@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;600;700;800;900&display=swap');
html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* React Slick */
.slick-dots {
  top: 67%;
}

.slick-prev {
  left: 3% !important;
  z-index: 1;
}
.slick-next {
  right: 3% !important;
  z-index: 1;
}

.slick-dots li button:before {
  font-family: 'slick' !important;
  font-size: 0.75rem !important;
  line-height: 20px !important;
  color: #0b488f !important;
}

.slick-dots li.slick-active button:before {
  color: #9b2828 !important;
}


.module {
  width: auto;
  height: 250px;
  display: grid;
  place-items: center;
  color: #ff43ea;
  position: relative;
}
.module::before {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url('./assets/Smooth_background_blur_1.jpg');
  background-size: cover;
  filter: grayscale(100%);
}
.module-inside {
  position: relative;
  font: bold 42px sans-serif;
}

/* Remove up/down arrows in input type number */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

::-webkit-calendar-picker-indicator {
  filter: brightness(0) invert(1);
}